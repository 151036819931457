// @ts-nocheck

import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

import { dollarFormatter, getBlockchainHashLink } from "../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";

import UpRightIcon from "../../assets/icons/up-right.svg";
import DownLeftIcon from "../../assets/icons/down-left.svg";
import { FaEllipsisV } from "react-icons/fa";
import IconButton from "../../components/Buttons/IconButton";
import { approveWithdrawal, getTransactions, updateTransactionStatus } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/loading";


const PaymentWithdrawals = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [receivedPayments, setReceivedPayments] = useState([]);
    const [failedPayments, setFailedPayments] = useState([]);
    const [proceedWithdrawals, setProceedWithdrawals] = useState([]);
    const [pendingWithdrawals, setPendingWithdrawals] = useState([]);

    useEffect(() => {
        loadReceivedPayments();
        loadFailedPayments();
        loadProceedWithdrawals();
        loadPendingWithdrawals();
    }, []);

    const loadReceivedPayments = async () => {
        const res = await getTransactions({ kind: "deposit", status: 1, pageSize: 6 });
        if (res.status === 200) {
            setReceivedPayments(res.data.items);
        }
    }

    const loadFailedPayments = async () => {
        const res = await getTransactions({ kind: "deposit", status: 2, pageSize: 6 });
        if (res.status === 200) {
            setFailedPayments(res.data.items);
        }
    }

    const loadProceedWithdrawals = async () => {
        const res = await getTransactions({ kind: "withdrawal", status: 1, pageSize: 6 });
        if (res.status === 200) {
            setProceedWithdrawals(res.data.items);
        }
    }

    const loadPendingWithdrawals = async () => {
        const res = await getTransactions({ kind: "withdrawal", status: 0, pageSize: 6 });
        if (res.status === 200) {
            setPendingWithdrawals(res.data.items);
        }
    }

    const handleView = (item) => {
        if (item.kind === "deposit") {
            navigate("/pws/payment", {
                state: item
            });
        } else {
            navigate("/pws/withdrawal", {
                state: item
            });
        }
    }

    const handleApproveWithdrawal = async (item) => {
        setIsLoading(true);
        const res = await approveWithdrawal(item._id);
        if (res.status === 200) {
            loadProceedWithdrawals();
            loadPendingWithdrawals();
        } else {
            toast.error(res.data.message);
        }
        setIsLoading(false);
    }
    const handleRejectWithdrawal = async (item) => {
        const res = await updateTransactionStatus(item._id, 2);
        if (res.status === 200) {
            loadProceedWithdrawals();
            loadPendingWithdrawals();
        } else {
            toast.error(res.data.message);
        }
    }
    const handleHoldWithdrawal = async (item) => {
        const res = await updateTransactionStatus(item._id, 3);
        if (res.status === 200) {
            loadProceedWithdrawals();
            loadPendingWithdrawals();
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold">
                            Payments and Withdrawals Management
                        </div>
                        <div className="text-gray6 text-[12px] font-[600]">
                            Manage financial flows
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                    <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Received Payments</p>
                        </div>

                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Order ID</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">User</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Amount</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Status</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                            </div>
                            {receivedPayments.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item._id}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        {item.status === 1 ?
                                            <a className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer"
                                                href={getBlockchainHashLink(item.txHash)} target="_blank" rel="noreferrer"
                                            >
                                                Completed
                                            </a> :
                                            <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                Pending
                                            </span>
                                        }
                                    </div>
                                    <div className="w-[10%] min-w-[100px] flex justify-end">
                                        <IconButton
                                            text="View Details" TxSize="text-[12px]" py="py-[4px]" className="w-[100px]"
                                            onClick={() => handleView(item)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Processed Withdrawals</p>
                        </div>

                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Order ID</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">User</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Amount</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Status</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                            </div>
                            {proceedWithdrawals.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item._id}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            {item.nfts?.length > 0 ? `NFT ${item.nfts?.map((nft: any) => `#${nft}`)?.join(",")}` : dollarFormatter(item.amount)}
                                        </p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        {item.status === 1 ?
                                            <a className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer"
                                                href={getBlockchainHashLink(item.txHash)} target="_blank" rel="noreferrer"
                                            >
                                                Completed
                                            </a> :
                                            <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                Pending
                                            </span>
                                        }
                                    </div>
                                    <div className="w-[10%] min-w-[100px] flex justify-end">
                                        <IconButton
                                            text="View Details" TxSize="text-[12px]" py="py-[4px]" className="w-[100px]"
                                            onClick={() => handleView(item)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Withdrawal Requests</p>
                        </div>

                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Order ID</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">User</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Amount</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Status</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                            </div>
                            {pendingWithdrawals.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item._id}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            {item.nfts?.length > 0 ? `NFT ${item.nfts?.map((nft: any) => `#${nft}`)?.join(",")}` : dollarFormatter(item.amount)}
                                        </p>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        {item.status === 1 ?
                                            <span className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer">
                                                Completed
                                            </span> :
                                            <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                Pending
                                            </span>
                                        }
                                    </div>
                                    <div className="w-[10%] min-w-[100px] flex justify-end">
                                        <Menu placement="bottom-end">
                                            <MenuHandler>
                                                <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                            </MenuHandler>
                                            <MenuList>
                                                <MenuItem className="text-[#48B825] text-[14px]" onClick={() => handleApproveWithdrawal(item)}>Approve</MenuItem>
                                                <MenuItem className="text-[#FF5C5C] text-[14px]" onClick={() => handleRejectWithdrawal(item)}>Reject</MenuItem>
                                                <MenuItem className="text-[#F7BA00] text-[14px]" onClick={() => handleHoldWithdrawal(item)}>Hold</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="bg-white w-full p-[21.5px_32px] rounded-[8px] hidden">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Problematic Payments</p>
                        </div>

                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                                <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Payment ID</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">User</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Amount</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Issue</p>
                                <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500]">Status</p>
                                <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                            </div>
                            {failedPayments.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                    <div className="w-[20%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item._id}</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            {item.nfts?.length > 0 ? `NFT ${item.nfts?.map((nft: any) => `#${nft}`)?.join(",")}` : dollarFormatter(item.amount)}
                                        </p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">Failed Payment</p>
                                    </div>
                                    <div className="w-[15%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[10%] min-w-[100px]">
                                        {item.status === 1 ?
                                            <span className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer">
                                                Completed
                                            </span> :
                                            <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                In Progress
                                            </span>
                                        }
                                    </div>
                                    <div className="w-[10%] min-w-[100px] flex justify-end">
                                        <Menu placement="bottom-end">
                                            <MenuHandler>
                                                <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                            </MenuHandler>
                                            <MenuList>
                                                <MenuItem className="text-black text-[14px]">Contact User</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentWithdrawals;