// @ ts-nocheck

import React from "react";
import { useCopyToClipboard } from 'usehooks-ts'

import { dollarFormatter, getBlockchainHashLink, getFileUrl } from "../../utils/utils";
import { useLocation } from "react-router-dom";

import PersonImg from "../../assets/image/persons/8.png";
import USDCIcon from "../../assets/icons/usdc.svg";
import CopyIcon from "../../assets/icons/copy.svg";
import dayjs from "dayjs";
import { DATETIME_FORMAT, TransactionStatuses } from "../../utils/contants";

const PaymentDetail = () => {

    const location = useLocation();
    const [copiedText, copy] = useCopyToClipboard();

    const itemInfo = location?.state;

    const handleCopyAddress = (address: any) => {
        copy(address)
            .then(() => {
                console.log('Copied!', copiedText)
            })
            .catch(error => {
                console.error('Failed to copy!', error)
            });
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Payment Details
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="bg-white w-full p-[15px_24px] rounded-[8px] flex flex-col lg:flex-row gap-3 justify-between">
                    <div className="flex flex-col gap-3 self-start text-[16px] text-black">
                        <div className="text-[18px] font-[600] mb-2">Payment Information</div>
                        <div>
                            <span className="font-[600]">Payment ID: </span>
                            <span className="font-[500]">{itemInfo?._id}</span>
                        </div>
                        <div>
                            <span className="font-[600]">Date: </span>
                            <span className="font-[500]">{dayjs(itemInfo?.createdAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}</span>
                        </div>
                        <div>
                            <span className="font-[600]">Amount: </span>
                            <span className="font-[500]">{dollarFormatter(itemInfo?.amount)}</span>
                        </div>
                        <div>
                            <span className="font-[600]">Status: </span>
                            <a className={itemInfo?.status === 1 ? 'text-[#55BA68]' : 'text-[#F86E51]'}
                                href={getBlockchainHashLink(itemInfo?.txHash)} target="_blank" rel="noreferrer"
                            >
                                {TransactionStatuses[itemInfo?.status]}
                            </a>
                        </div>
                    </div>
                    {/* <div className="flex flex-col gap-3 justify-center">
                        <button className="text-black font-[600] hover:text-white border border-[#0000001A] hover:bg-[#48B825] p-[8px_12px] min-w-[150px] lg:w-[170px] rounded-[6px]">Approve Payment</button>
                        <button className="text-[#FF3030] font-[600] hover:text-white border border-[#FF303033] hover:bg-[#c93838] p-[8px_12px] min-w-[150px] lg:w-[170px] rounded-[6px]">Reject Payment</button>
                        <button className="text-black font-[600] hover:text-[#FF3030] border border-[#0000001A] hover:border-[#FF303033] p-[8px_12px] min-w-[150px] lg:w-[170px] rounded-[6px]">Hold Payment</button>
                    </div> */}
                </div>

                <div className="bg-white w-full p-[15px_24px] rounded-[8px] flex flex-col gap-3 justify-between">
                    <div className="text-[18px] font-[600] mb-2">User Information</div>
                    <div className="flex flex-col lg:flex-row items-center gap-2 lg:gap-6">
                        <div className="flex self-start items-center gap-3">
                            <img src={itemInfo?.user?.avatar ? getFileUrl(itemInfo?.user?.avatar) : PersonImg} className="w-[73px] h-[73px] lg:w-[140px] lg:h-[140px] rounded-full" alt="person" />
                            <div className="text-black text-[20px] font-[600] lg:hidden">{itemInfo?.user?.fullname}</div>
                        </div>
                        <div className="flex flex-col gap-3 self-start">
                            <div className="text-black text-[20px] font-[600] hidden lg:block">{itemInfo?.user?.fullname}</div>
                            <div className="flex items-center border border-border2 rounded-lg">
                                <div className="p-[10px] flex flex-shrink items-center gap-2 border-r border-border2 overflow-hidden">
                                    <img src={USDCIcon} alt="chain" className="w-[18px] h-[18px]" />
                                    <span className="text-black text-[12px] font-[600]">{itemInfo?.user?.depositAddress}</span>
                                </div>
                                <div className="p-[10px] cursor-pointer min-w-[40px] flex-shrink-0" onClick={() => handleCopyAddress(itemInfo?.user?.depositAddress)}>
                                    <img src={CopyIcon} alt="copy" />
                                </div>
                            </div>
                            <div className="text-[14px] font-[500] flex flex-col gap-2">
                                <div>
                                    <span className="text-gray6">Email: </span>
                                    <span className="text-black">{itemInfo?.user?.email}</span>
                                </div>
                                <div>
                                    <span className="text-gray6">Account ID: </span>
                                    <span className="text-black">{itemInfo?.user?._id}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDetail;