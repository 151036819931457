// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useCopyToClipboard } from 'usehooks-ts'

import { dollarFormatter, getFileUrl, showConfirmBox } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";

import PersonImg from "../../assets/image/persons/8.png";
import SolanaIcon from "../../assets/icons/chains/solana.png";
import CopyIcon from "../../assets/icons/copy.svg";
import IconButton from "../../components/Buttons/IconButton";
import { getActivities, getTransactions, updateUser } from "../../services/axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";
import { CSVLink } from "react-csv";

const UserProfile = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [copiedText, copy] = useCopyToClipboard();

    const userInfo = location?.state;

    const [transactions, setTransactions] = useState([]);
    const [activities, setActivities] = useState([]);
    const [investHistories, setInvestHistories] = useState([]);

    useEffect(() => {
        if (userInfo) {
            loadRecentTransactions(userInfo._id);
            loadRecentActivities(userInfo._id);
            loadInvestTransactions(userInfo._id);
        }
    }, [userInfo]);

    const loadRecentTransactions = async (userId: any) => {
        const res = await getTransactions({ userId, page: 1, pageSize: 6 });
        if (res.status === 200) {
            setTransactions(res.data.items);
        }
    }
    const loadRecentActivities = async (userId: any) => {
        const res = await getActivities({ userId, page: 1, pageSize: 6 });
        if (res.status === 200) {
            setActivities(res.data.items);
        }
    }
    const loadInvestTransactions = async (userId: any) => {
        const res = await getTransactions({ userId, kind: "invest", page: 1, pageSize: 6 });
        if (res.status === 200) {
            setInvestHistories(res.data.items);
        }
    }

    const handleCopyAddress = (address: any) => {
        copy(address)
            .then(() => {
                toast.success("Copied address!");
                console.log('Copied!', { address })
            })
            .catch(error => {
                console.error('Failed to copy!', error)
            });
    }

    const handleUpdateStatus = () => {
        showConfirmBox("Warning", `Are you sure to ${userInfo.status === 1 ? 'suspend' : 'active'} user?`,
            async () => {
                const res = await updateUser(userInfo._id, {
                    status: userInfo.status === 1 ? 2 : 1
                });
                if (res.status === 200) {
                    toast.success("Update successfully!");
                    navigate(location, {
                        replace: true,
                        state: { ...userInfo, status: userInfo.status === 1 ? 2 : 1 }
                    });
                } else {
                    toast.error(res.data.message);
                }
            });
    }

    // const handleResetPassword = () => {
    //     showConfirmBox("Warning", "Are you sure to reset user password?",
    //         async () => {

    //         });
    // }

    const handleSendNotification = () => {

    }

    const handleViewTransactions = () => {
        navigate("/users/transactions", {
            state: userInfo
        });
    }

    const handleViewActivities = () => {
        navigate("/users/activities", {
            state: userInfo
        });
    }

    const handleViewInvests = () => {
        navigate("/users/transactions", {
            state: userInfo
        });
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        User Profile
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Manage User Profile
                    </div>
                </div>
            </div>


            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="bg-white w-full p-[15px_24px] rounded-[8px] flex flex-col lg:flex-row gap-3 justify-between">
                    <div className="flex flex-col lg:flex-row items-center gap-2 lg:gap-6">
                        <div className="flex self-start items-center gap-3">
                            <img src={userInfo.avatar ? getFileUrl(userInfo.avatar) : PersonImg} className="w-[73px] h-[73px] lg:w-[140px] lg:h-[140px] rounded-full" alt="person" />
                            <div className="text-black text-[20px] font-[600] lg:hidden">{userInfo.fullname}</div>
                        </div>
                        <div className="flex flex-col gap-3 self-start">
                            <div className="text-black text-[20px] font-[600] hidden lg:block">{userInfo.fullname}</div>
                            <div className="flex items-center border border-border2 rounded-lg">
                                <div className="p-[10px] flex flex-shrink items-center gap-2 border-r border-border2 overflow-hidden">
                                    <img src={SolanaIcon} alt="chain" className="w-[18px] h-[18px]" />
                                    <span className="text-black text-[12px] font-[600]">{userInfo.depositAddress}</span>
                                </div>
                                <div className="p-[10px] cursor-pointer min-w-[40px] flex-shrink-0" onClick={() => handleCopyAddress(userInfo.depositAddress)}>
                                    <img src={CopyIcon} alt="copy" />
                                </div>
                            </div>
                            <div className="text-[14px] font-[500] flex flex-col gap-2">
                                <div>
                                    <span className="text-gray6">Email: </span>
                                    <span className="text-black">{userInfo.email}</span>
                                </div>
                                <div>
                                    <span className="text-gray6">Role: </span>
                                    <span className="text-black">{userInfo.role ?? "User"}</span>
                                </div>
                                <div>
                                    <span className="text-gray6">Verified: </span>
                                    <span className="text-black">{userInfo.verified ? 'Confirmed' : 'Not verified'}</span>
                                </div>
                                <div>
                                    <span className="text-gray6">Status: </span>
                                    {userInfo.status === 0 ?
                                        <span className="text-[#F86E51]">Pending</span> :
                                        userInfo.status === 1 ?
                                            <span className="text-[#55BA68]">Active</span> :
                                            <span className="text-[#F86E51]">Suspended</span>
                                    }
                                </div>
                                <div>
                                    <span className="text-gray6">KYC: </span>
                                    <span className={userInfo.kyc ? 'text-[#55BA68]' : 'text-[#F86E51]'}>{userInfo.kyc ? 'Done' : 'Pending'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-1 h-full gap-3">
                        <button
                            className="text-black font-[600] hover:text-[#FF3030] border border-[#0000001A] hover:border-[#FF303033] p-[8px_12px] min-w-[150px] lg:w-[170px] rounded-[6px]"
                            onClick={handleUpdateStatus}
                        >
                            {userInfo.status === 1 ? 'Suspend User' : 'Active User'}
                        </button>
                        {/* <button
                            className="text-black font-[600] hover:text-[#FF3030] border border-[#0000001A] hover:border-[#FF303033] p-[8px_12px] min-w-[150px] lg:w-[170px] rounded-[6px]"
                            onClick={handleResetPassword}
                        >
                            Reset Password
                        </button> */}
                        <button
                            className="text-black font-[600] hover:text-[#FF3030] border border-[#0000001A] hover:border-[#FF303033] p-[8px_12px] min-w-[150px] lg:w-[170px] rounded-[6px]"
                            onClick={handleSendNotification}
                        >
                            Send notification
                        </button>
                        <CSVLink
                            data={[userInfo]}
                            filename={"UserProfile.csv"}
                            className="text-center text-black font-[600] hover:text-[#FF3030] border border-[#0000001A] hover:border-[#FF303033] p-[8px_12px] min-w-[150px] lg:w-[170px] rounded-[6px]"
                        >
                            Download Report
                        </CSVLink>
                    </div>
                </div>

                <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
                    <div className="bg-white p-6 rounded-[8px] lg:col-span-2">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Transaction History</p>
                            <IconButton
                                text="View All" TxSize="text-[12px]" py="py-[8px]" className="w-[100px]"
                                onClick={handleViewTransactions}
                            />
                        </div>
                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <p className="w-[30%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                                <p className="w-[50%] min-w-[200px] text-gray6 text-[14px] font-[500]">Details</p>
                                <p className="w-[20%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Amount</p>
                            </div>
                            {transactions.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                    <div className="w-[30%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[50%] min-w-[200px]">
                                        <p className="text-[14px] font-[500] text-black">{item.description}</p>
                                    </div>
                                    <div className="w-[20%] min-w-[100px] flex justify-end">
                                        <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Recent Activity</p>
                            <IconButton
                                text="View All" TxSize="text-[12px]" py="py-[8px]" className="w-[100px]"
                                onClick={handleViewActivities}
                            />
                        </div>
                        <div className="flex flex-col w-full overflow-x-auto">
                            {activities.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-start py-3 gap-2 border-b border-border2">
                                    <div className="w-1 h-1 rounded-full bg-[#7A7E82]"></div>
                                    <p className="text-[14px] font-[500] text-black">{item.name}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">Cars Invested and Amounts</p>
                        <IconButton
                            text="View All" TxSize="text-[12px]" py="py-[8px]" className="w-[100px]"
                            onClick={handleViewInvests}
                        />
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Car Name</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Investment Date</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Amount Invested</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Status</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">NFT</p>
                        </div>
                        {investHistories.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[20%] min-w-[150px] flex items-center gap-2">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{item.car?.name}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    {item.status === 1 ?
                                        <span className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer">
                                            Active
                                        </span> :
                                        <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                            Failed
                                        </span>
                                    }
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">
                                        {item.nfts?.length > 0 && `${item.nfts?.map((nft: any) => `#${nft}`)?.join(",")}`}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;