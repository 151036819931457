// @ts-nocheck

import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

import { dollarFormatter, getRisingPercent, getRisingValue, showConfirmBox } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import {
    Progress, Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";

import UpRightIcon from "../../assets/icons/up-right.svg";
import DownLeftIcon from "../../assets/icons/down-left.svg";
import { FaEllipsisV } from "react-icons/fa";
import IconButton from "../../components/Buttons/IconButton";
import { getCarsList, getCarsOverview, getTransactions, updateCar } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";
import { toast } from "react-toastify";

const _overViewInfo = [
    {
        id: "total_funds",
        title: "Total Funds Raised",
        prefix: "$",
        value: 100000,
        decimals: 0,
        isRising: true,
        risingPercent: 10.8,
        risingValue: "+18.4K",
    },
    {
        id: "total_projects",
        title: "Total Projects",
        value: 20,
        decimals: 0,
        isRising: false,
        risingPercent: 10.8,
        risingValue: "-15K",
    },
    {
        id: "progress_projects",
        title: "Projects in Progress",
        value: 5,
        decimals: 0,
        isRising: true,
        risingPercent: 10.8,
        risingValue: "+18.4K",
    },
    {
        id: "complete_projects",
        title: "Completed Projects",
        value: 15,
        decimals: 0,
        isRising: false,
        risingPercent: 10.8,
        risingValue: "-15K",
    }
];


const Projects = () => {

    const navigate = useNavigate();

    const [overviewData, setOverviewData] = useState(_overViewInfo);
    const [ongoingProjects, setOngoingProjects] = useState([]);
    const [completeProjects, setCompleteProjects] = useState([]);
    const [newProjects, setNewProjects] = useState([]);
    const [investors, setInvestors] = useState([]);

    useEffect(() => {
        loadOverview();
        loadOngoings();
        loadComplets();
        loadNews();
        loadInvestors();
    }, []);

    const loadOverview = async () => {
        const res = await getCarsOverview();
        if (res.status === 200) {
            let overviewInfo = [..._overViewInfo];

            overviewInfo[0].value = res.data.totalFunds.total;
            overviewInfo[0].isRising = res.data.totalFunds.thisWeek >= res.data.totalFunds.prevWeek;
            overviewInfo[0].risingPercent = getRisingPercent(res.data.totalFunds.thisWeek, res.data.totalFunds.prevWeek);
            overviewInfo[0].risingValue = getRisingValue(res.data.totalFunds.thisWeek, res.data.totalFunds.prevWeek);

            overviewInfo[1].value = res.data.totalCounts.total;
            overviewInfo[1].isRising = res.data.totalCounts.thisWeek >= res.data.totalCounts.prevWeek;
            overviewInfo[1].risingPercent = getRisingPercent(res.data.totalCounts.thisWeek, res.data.totalCounts.prevWeek);
            overviewInfo[1].risingValue = getRisingValue(res.data.totalCounts.thisWeek, res.data.totalCounts.prevWeek);

            overviewInfo[2].value = res.data.ongoings.total;
            overviewInfo[2].isRising = res.data.ongoings.thisWeek >= res.data.ongoings.prevWeek;
            overviewInfo[2].risingPercent = getRisingPercent(res.data.ongoings.thisWeek, res.data.ongoings.prevWeek);
            overviewInfo[2].risingValue = getRisingValue(res.data.ongoings.thisWeek, res.data.ongoings.prevWeek);

            overviewInfo[3].value = res.data.completes.total;
            overviewInfo[3].isRising = res.data.completes.thisWeek >= res.data.completes.prevWeek;
            overviewInfo[3].risingPercent = getRisingPercent(res.data.completes.thisWeek, res.data.completes.prevWeek);
            overviewInfo[3].risingValue = getRisingValue(res.data.completes.thisWeek, res.data.completes.prevWeek);

            setOverviewData(overviewInfo);
        }
    }

    const loadOngoings = async () => {
        const res = await getCarsList({ status: [1, 2] });
        if (res.status === 200) {
            setOngoingProjects(res.data.items);
        }
    }
    const loadComplets = async () => {
        const res = await getCarsList({ status: 3 });
        if (res.status === 200) {
            setCompleteProjects(res.data.items);
        }
    }
    const loadNews = async () => {
        const res = await getCarsList({ status: 0 });
        if (res.status === 200) {
            setNewProjects(res.data.items);
        }
    }
    const loadInvestors = async () => {
        const res = await getTransactions({
            page: 1, pageSize: 6, kind: "invest"
        });
        if (res.status === 200) {
            setInvestors(res.data.items);
        }
    }

    const handleAdd = () => {
        navigate("/cars/edit");
    }

    const handleView = (item) => {
        navigate("/cars/edit", {
            state: item
        });
    }
    const handleTrack = (item) => {
        navigate("/projects/investors", {
            state: item
        });
    }
    const handleApprove = (item) => {
        showConfirmBox("Warning", `Are you sure to approve`,
            async () => {
                const res = await updateCar(item._id, {
                    status: 1
                });
                if (res.status === 200) {
                    toast.success("Update successfully!");
                    loadOngoings();
                    loadNews();
                } else {
                    toast.error(res.data.message);
                }
            });
    }
    const handleReject = (item) => {
        showConfirmBox("Warning", `Are you sure to reject`,
            async () => {
                const res = await updateCar(item._id, {
                    status: 4
                });
                if (res.status === 200) {
                    toast.success("Update successfully!");
                    loadNews();
                } else {
                    toast.error(res.data.message);
                }
            });
    }
    const handleViewProfile = (item) => {
        navigate("/users/profile", {
            state: item.user
        });
    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Manage Tokenized Projects
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Manage all tokenized Projects
                    </div>
                </div>
                <IconButton text="Add New Car" TxSize="text-[14px]" py="py-[10px]" className="w-[150px] hidden sm:block" onClick={handleAdd} />
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <IconButton text="Add New Project" TxSize="text-[14px]" py="py-[10px]" className="w-full max-w-[300px] sm:hidden" onClick={handleAdd} />

                <div className="w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-5">
                    {
                        overviewData.map((item, index) =>
                            <div key={index} className="bg-white h-full p-6 relative select-none rounded-[8px]">
                                <p className="text-[16px] font-[600] text-gray6">{item.title}</p>
                                <p className="text-black text-[29px] font-semibold mt-2">{item.prefix ?? ''} <CountUp end={item.value} separator="," decimals={item.decimals} /></p>
                                <div className="flex items-center mt-6 gap-2">
                                    {item.isRising ?
                                        <img src={UpRightIcon} alt="up" className="w-4 h-4" />
                                        :
                                        <img src={DownLeftIcon} alt="down" className="w-4 h-4" />
                                    }
                                    <label className={`text-[14px] font-semibold ${item.isRising ? "text-[#55BA68]" : "text-[#F86E51]"}`}>{item.risingPercent}%</label>
                                    <label className="text-[14px] font-semibold text-gray6">{item.risingValue} this week</label>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">Ongoing Project</p>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[25%] min-w-[200px] text-gray6 text-[14px] font-[500]">Project Name</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Funds Raised</p>
                            <p className="w-[25%] min-w-[200px] text-gray6 text-[14px] font-[500]">Progress Status</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Timeline</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {ongoingProjects.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[25%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{item.name}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{dollarFormatter(item.fundsRaised)}</p>
                                </div>
                                <div className="w-[25%] min-w-[200px] flex items-center gap-2">
                                    <div className="w-[150px]">
                                        <Progress value={item.progress} color="green" />
                                    </div>
                                    <p className="text-[14px] font-[500] text-black ">
                                        {item.progress} %
                                    </p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">
                                        {dayjs(item.startAt).format(DATETIME_FORMAT.SHORT_DATE_STR)} - {dayjs(item.endAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                                    </p>
                                </div>
                                <div className="w-[10%] min-w-[100px] flex justify-end relative">
                                    <Menu placement="bottom-end">
                                        <MenuHandler>
                                            <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                        </MenuHandler>
                                        <MenuList>
                                            <MenuItem className="text-black text-[14px]" onClick={() => handleView(item)}>View Details</MenuItem>
                                            <MenuItem className="text-black text-[14px]" onClick={() => handleTrack(item)}>Track Investors</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">Completed Projects</p>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[25%] min-w-[200px] text-gray6 text-[14px] font-[500]">Project Name</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Funds Raised</p>
                            <p className="w-[25%] min-w-[200px] text-gray6 text-[14px] font-[500]">Progress Status</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Timeline</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {completeProjects.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[25%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{item.name}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{dollarFormatter(item.fundsRaised)}</p>
                                </div>
                                <div className="w-[25%] min-w-[200px] flex items-center gap-2">
                                    <div className="w-[150px]">
                                        <Progress value={100} color="green" />
                                    </div>
                                    <p className="text-[14px] font-[500] text-black ">
                                        {100} %
                                    </p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">
                                        {dayjs(item.startAt).format(DATETIME_FORMAT.SHORT_DATE_STR)} - {dayjs(item.endAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                                    </p>
                                </div>
                                <div className="w-[10%] min-w-[100px] flex justify-end">
                                    <Menu placement="bottom-end">
                                        <MenuHandler>
                                            <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                        </MenuHandler>
                                        <MenuList>
                                            <MenuItem className="text-black text-[14px]" onClick={() => handleView(item)}>View Details</MenuItem>
                                            <MenuItem className="text-black text-[14px]" onClick={() => handleTrack(item)}>Track Investors</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">New Project Approval</p>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[25%] min-w-[200px] text-gray6 text-[14px] font-[500]">Project Name</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Expected Funds</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Proposed Timeline</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {newProjects.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[25%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{item.name}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">{dollarFormatter(item.amountFunded)}</p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black overflow-hidden">
                                        {dayjs(item.startAt).format(DATETIME_FORMAT.SHORT_DATE_STR)} - {dayjs(item.endAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}
                                    </p>
                                </div>
                                <div className="w-[10%] min-w-[100px] flex justify-end">
                                    <Menu placement="bottom-end">
                                        <MenuHandler>
                                            <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                        </MenuHandler>
                                        <MenuList>
                                            <MenuItem className="text-[#48B825] text-[14px]" onClick={() => handleApprove(item)}>Approve</MenuItem>
                                            <MenuItem className="text-[#FF5C5C] text-[14px]" onClick={() => handleReject(item)}>Reject</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">Investor Management</p>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[45%] min-w-[200px] text-gray6 text-[14px] font-[500]">Investor Name</p>
                            <p className="w-[45%] min-w-[200px] text-gray6 text-[14px] font-[500]">Investments</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {investors.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[45%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                </div>
                                <div className="w-[45%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                </div>
                                <div className="w-[10%] min-w-[100px] flex justify-end">
                                    <IconButton
                                        text="View Profile" TxSize="text-[12px]" py="py-[4px]" className="w-[100px]"
                                        onClick={() => handleViewProfile(item)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;