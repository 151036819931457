import { api } from "./api";

// ================= system config ============================
export const getConfigs = async (params: any) => {
    try {
        const result = await api.get("/configs");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const updateConfigs = async (params: any) => {
    try {
        const result = await api.post("/configs", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================= auth ============================
export const signInUser = async (params: any) => {
    try {
        const result = await api.post("/login", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================= 2fa ============================
export const validateOTP = async (params: any) => {
    try {
        const result = await api.post("/otp/validate", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const generateOTP = async (params: any) => {
    try {
        const result = await api.post("/otp/generate", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const verifyOTP = async (params: any) => {
    try {
        const result = await api.post("/otp/verify", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const disableOTP = async (params: any) => {
    try {
        const result = await api.post("/otp/disable", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================= admin management ============================
export const getAdmins = async (params: any) => {
    try {
        const result = await api.get("/roles", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const addOrUpdateAdmin = async (params: any) => {
    try {
        const result = await api.post("/roles/update", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const deleteAdmin = async (id: any) => {
    try {
        const result = await api.post(`/roles/delete/${id}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================= notification ============================
export const getNotifications = async (params: any) => {
    try {
        const result = await api.get("/notifications", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const deleteNotification = async (id: any) => {
    try {
        const result = await api.post(`/notifications/delete/${id}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const favorNotification = async (id: any) => {
    try {
        const result = await api.post(`/notifications/favor/${id}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================= documents ============================
export const getDocuments = async (params: any) => {
    try {
        const result = await api.get("/documents", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const addDocument = async (params: any) => {
    try {
        const result = await api.post(`/documents/add`, params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const updateDocument = async (id: any, params: any) => {
    try {
        const result = await api.post(`/documents/update/${id}`, params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const deleteDocument = async (id: any) => {
    try {
        const result = await api.post(`/documents/delete/${id}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================= tokenized cars ============================
export const getCarsList = async (params: any) => {
    try {
        const result = await api.get("/cars", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getCarsOverview = async () => {
    try {
        const result = await api.get("/cars/overview");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const addCar = async (params: any) => {
    try {
        const result = await api.post(`/cars/add`, params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const updateCar = async (id: any, params: any) => {
    try {
        const result = await api.post(`/cars/update/${id}`, params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const deleteCar = async (id: any) => {
    try {
        const result = await api.post(`/cars/delete/${id}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}


// ================= cms blogs, pages, faqs ============================
// type : blog, page, faq

export const getCMSData = async (type: any, params: any) => {
    try {
        const result = await api.get(
            type === 'faq' ? "/faqs" : "/blog_pages",
            {
                params: {
                    type,
                    ...params
                }
            }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const addCMSItem = async (type: any, params: any) => {
    try {
        const result = await api.post(
            type === 'faq' ? `/faqs/add` : `blog_pages/add`,
            { type, ...params }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const updateCMSItem = async (id: any, type: any, params: any) => {
    try {
        const result = await api.post(
            type === 'faq' ? `/faqs/update/${id}` : `blog_pages/update/${id}`,
            { type, ...params }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const deleteCMSItems = async (ids: any, type: any) => {
    try {
        const result = await api.post(
            type === 'faq' ? `/faqs/delete` : `blog_pages/delete`,
            { ids }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}


export const getCategories = async () => {
    try {
        const result = await api.get("/bp_categories");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const addCategory = async (name: any) => {
    try {
        const result = await api.post(`bp_categories/add`,
            { name }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================= users ============================
export const getUsers = async (params: any) => {
    try {
        const result = await api.get("/users", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getUsersOverview = async () => {
    try {
        const result = await api.get("/users/overview");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const updateUser = async (id: any, params: any) => {
    try {
        const result = await api.post(`/users/update/${id}`,
            params
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================ user's activities ===============
export const getActivities = async (params: any) => {
    try {
        const result = await api.get("/activities", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================ transactions ===============
export const getTransactions = async (params: any) => {
    try {
        const result = await api.get("/transactions", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getTransactionOverview = async () => {
    try {
        const result = await api.get("/transactions/overview");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const approveWithdrawal = async (txId: any) => {
    try {
        const result = await api.post(`/transactions/approve_withdrawal`,
            { txId }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const updateTransactionStatus = async (txId: any, status: any) => {
    try {
        const result = await api.post(`/transactions/update_status`,
            { txId, status }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================ nft ===============
export const getNfts = async (params: any) => {
    try {
        const result = await api.get("/nfts", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}

// ================ statistics ===============
export const getStatisticsOverview = async (date: any) => {
    try {
        const result = await api.get("/statistics/overview", {
            params: { date }
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getStatisticsMonthlyUsers = async () => {
    try {
        const result = await api.get("/statistics/monthly_users");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getStatisticsMonthlyVisitors = async () => {
    try {
        const result = await api.get("/statistics/monthly_visitors");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getStatisticsMonthlyTransactions = async (kind: any) => {
    try {
        const result = await api.get("/statistics/monthly_transactions", {
            params: { kind }
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getStatisticsTransactions = async () => {
    try {
        const result = await api.get("/statistics/transactions_info");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getStatisticsCountriesInfo = async () => {
    try {
        const result = await api.get("/statistics/countries_info");
        return result;
    } catch (error: any) {
        return error.response;
    }
}