// @ts-nocheck

import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

import { dollarFormatter, getBlockchainHashLink, getRisingPercent, getRisingValue } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";

import UpRightIcon from "../../assets/icons/up-right.svg";
import DownLeftIcon from "../../assets/icons/down-left.svg";
import { FaEllipsisV } from "react-icons/fa";
import IconButton from "../../components/Buttons/IconButton";
import { getTransactionOverview, getTransactions } from "../../services/axios";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/contants";


const Transactions = () => {

    const navigate = useNavigate();

    const [overviewNft, setOverviewNft] = useState({
        total: 0, prevWeek: 0, thisWeek: 0
    });
    const [overviewInvest, setOverviewInvest] = useState({
        total: 0, prevWeek: 0, thisWeek: 0
    });
    const [overviewFees, setOverviewFees] = useState({
        transactionFee: 0, platformFee: 0
    });

    const [transactions, setTransactions] = useState([]);
    const [disputeTransactions, setDisputeTransactions] = useState([]);
    const [pendingTransactions, setPendingTransactions] = useState([]);

    useEffect(() => {
        loadOverview();
        loadTransactions();
        loadDisputeTransactions();
        loadPendingTransactions();
    }, []);

    const loadOverview = async () => {
        const res = await getTransactionOverview();
        if (res.status === 200) {
            setOverviewNft(res.data.nftInfo);
            setOverviewInvest(res.data.investInfo);
            setOverviewFees(res.data.feeInfo);
        }
    }
    const loadTransactions = async () => {
        const res = await getTransactions({ page: 1, pageSize: 6 });
        if (res.status === 200) {
            setTransactions(res.data.items);
        }
    }
    const loadDisputeTransactions = async () => {
        const res = await getTransactions({ status: 2, page: 1, pageSize: 6 });
        if (res.status === 200) {
            setDisputeTransactions(res.data.items);
        }
    }
    const loadPendingTransactions = async () => {
        const res = await getTransactions({ status: 0, page: 1, pageSize: 6 });
        if (res.status === 200) {
            setPendingTransactions(res.data.items);
        }
    }

    const handleView = (item) => {
        window.open(getBlockchainHashLink(item.txHash), "_blank");
    }
    const handleResolve = (item) => {

    }
    const handleEscalate = (item) => {

    }
    const handleReject = (item) => {

    }
    const handleApprove = (item) => {

    }
    const handleHold = (item) => {

    }

    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-border2">
                <div>
                    <div className="text-black text-[20px] font-Urbanist-Bold">
                        Transaction Management
                    </div>
                    <div className="text-gray6 text-[12px] font-[600]">
                        Manage fiat and crypto transactions
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
                    <div className="bg-white h-full p-6 relative select-none rounded-[8px]">
                        <p className="text-[16px] font-[600] text-gray6">Total NFT Sold</p>
                        <p className="text-black text-[29px] font-semibold mt-2"><CountUp end={overviewNft.total} separator="," decimals={0} /></p>
                        <div className="flex items-center mt-6 gap-2">
                            <img src={overviewNft.thisWeek >= overviewNft.prevWeek ? UpRightIcon : DownLeftIcon} alt="up" className="w-4 h-4" />
                            <label className={`text-[14px] font-semibold ${overviewNft.thisWeek >= overviewNft.prevWeek ? 'text-[#55BA68]' : 'text-[#F86E51]'}`}>
                                {getRisingPercent(overviewNft.thisWeek, overviewNft.prevWeek)}%
                            </label>
                            <label className="text-[14px] font-semibold text-gray6">
                                {getRisingValue(overviewNft.thisWeek, overviewNft.prevWeek)} this week
                            </label>
                        </div>
                    </div>

                    <div className="bg-white h-full p-6 relative select-none rounded-[8px]">
                        <p className="text-[16px] font-[600] text-gray6">Total Investment</p>
                        <p className="text-black text-[29px] font-semibold mt-2">$ <CountUp end={overviewInvest.total} separator="," decimals={0} /></p>
                        <div className="flex items-center mt-6 gap-2">
                            <img src={overviewInvest.thisWeek >= overviewInvest.prevWeek ? UpRightIcon : DownLeftIcon} alt="up" className="w-4 h-4" />
                            <label className={`text-[14px] font-semibold ${overviewInvest.thisWeek >= overviewInvest.prevWeek ? 'text-[#55BA68]' : 'text-[#F86E51]'}`}>
                                {getRisingPercent(overviewInvest.thisWeek, overviewInvest.prevWeek)}%
                            </label>
                            <label className="text-[14px] font-semibold text-gray6">
                                {getRisingValue(overviewInvest.thisWeek, overviewInvest.prevWeek)} this week
                            </label>
                        </div>
                    </div>

                    <div className="bg-white h-full p-6 relative select-none rounded-[8px]">
                        <p className="text-[16px] font-[600] text-gray6">Applied Fees Breakdown</p>
                        <p className="text-[16px] font-[500] text-gray6 mt-4">
                            <span>Transaction Fees: </span>
                            <span className="text-black font-[600]">{dollarFormatter(overviewFees.transactionFee)}</span>
                        </p>
                        <p className="text-[16px] font-[500] text-gray6 mt-2">
                            <span>Platform Fees: </span>
                            <span className="text-black font-[600]">{dollarFormatter(overviewFees.platformFee)}</span>
                        </p>
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600] flex-grow">Transaction History</p>
                        <IconButton text="View All" TxSize="text-[12px]" py="py-[8px]" className="w-[100px]" onClick={() => navigate("/transactions/all")} />
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">User</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Amount</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">NFT</p>
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Vehicle</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {transactions.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">
                                        {item.nfts?.length > 0 && `${item.nfts?.map((nft: any) => `#${nft}`)?.join(",")}`}
                                    </p>
                                </div>
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.car?.name}</p>
                                </div>
                                <div className="w-[10%] min-w-[100px] flex justify-end">
                                    <IconButton
                                        text="View Detail" TxSize="text-[12px]" py="py-[4px]" className="w-[100px]"
                                        onClick={() => handleView(item)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">Dispute Management</p>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Dispute ID</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">User</p>
                            <p className="w-[20%] min-w-[200px] text-gray6 text-[14px] font-[500]">Reason</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Status</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Vehicle</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {disputeTransactions.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[20%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item._id}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                </div>
                                <div className="w-[20%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black">{item.disputeReason}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                        Pending
                                    </span>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.car?.name}</p>
                                </div>
                                <div className="w-[10%] min-w-[100px] flex justify-end">
                                    <Menu placement="bottom-end">
                                        <MenuHandler>
                                            <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                        </MenuHandler>
                                        <MenuList>
                                            <MenuItem className="text-[#48B825] text-[14px]" onClick={() => handleResolve(item)}>Resolve</MenuItem>
                                            <MenuItem className="text-[#F7BA00] text-[14px]" onClick={() => handleEscalate(item)}>Escalate</MenuItem>
                                            <MenuItem className="text-[#FF5C5C] text-[14px]" onClick={() => handleReject(item)}>Reject</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-white w-full p-[21.5px_32px] rounded-[8px]">
                    <div className="w-full flex justify-between items-center mb-[18px]">
                        <p className="text-black text-[18px] font-[600]">Pending Transactions</p>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto">
                        <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-border2">
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Transaction ID</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">User</p>
                            <p className="w-[15%] min-w-[200px] text-gray6 text-[14px] font-[500]">Amount</p>
                            <p className="w-[15%] min-w-[150px] text-gray6 text-[14px] font-[500]">Vehicle</p>
                            <p className="w-[10%] min-w-[100px] text-gray6 text-[14px] font-[500] text-right">Action</p>
                        </div>
                        {pendingTransactions.map((item, index) =>
                            <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-border2">
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item._id}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.user?.fullname}</p>
                                </div>
                                <div className="w-[15%] min-w-[200px]">
                                    <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                </div>
                                <div className="w-[15%] min-w-[150px]">
                                    <p className="text-[14px] font-[500] text-black">{item.car?.name}</p>
                                </div>
                                <div className="w-[10%] min-w-[100px] flex justify-end">
                                    <Menu placement="bottom-end">
                                        <MenuHandler>
                                            <div><FaEllipsisV className="text-[#7A7E82] cursor-pointer" /></div>
                                        </MenuHandler>
                                        <MenuList>
                                            <MenuItem className="text-[#48B825] text-[14px]" onClick={() => handleApprove(item)}>Approve</MenuItem>
                                            <MenuItem className="text-[#FF5C5C] text-[14px]" onClick={() => handleReject(item)}>Reject</MenuItem>
                                            <MenuItem className="text-[#F7BA00] text-[14px]" onClick={() => handleHold(item)}>Hold</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Transactions;