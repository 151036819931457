import { useDropzone } from 'react-dropzone'
import UploadIcon from "../../../assets/icons/upload2.svg";
import { useEffect } from 'react';
import { apiMultipart } from '../../../services/api';
import { toast } from 'react-toastify';

const ImageDropZone = ({
  kind = 'files',
  accept = {
    'image/jpeg': [],
    'image/png': []
  },
  maxFiles = 10,
  onAcceptFiles = () => { }
}) => {

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    maxFiles
  });

  const upload = async () => {
    const formData = new FormData();
    acceptedFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    formData.append('kind', kind);

    try {
      const res = await apiMultipart.post('/upload', formData);
      if (res?.status === 200) {
        onAcceptFiles(res.data.result);
      } else {
        toast.error("File upload failed!");
      }
    } catch (err) {
    }
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      upload();
    }
  }, [acceptedFiles]);
  

  return (
    <div {...getRootProps()} className="w-full border border-dashed border-[#0E0E1E] bg-[#FAFAFA]  flex flex-col justify-center items-center gap-3 p-6 rounded-lg">
      <img src={UploadIcon} className="w-[42px] h-[42px]" alt="upload" />
      <div className="text-[#9B9DA5] text-[14px] font-[400] text-center">Recommended <span className="text-black">1160x724</span>px</div>
      <div className='text-[#9B9DA5] text-[14px] '>
        {acceptedFiles.map((file, index) => (
          <div key={index} className='text-[#0E0E1E]'>{file.path} - {file.size} bytes</div>
        ))}
      </div>
      <div className="flex items-center justify-center gap-3">
        <button className="text-[12px] text-[#0E0E1E] font-[500] hover:text-white bg-white hover:bg-[#48B825] border border-[#DDDDE3] p-[10px_16px] w-[130px] rounded-[6px] hidden md:block">Drop image here</button>
        <div className="text-[12px] text-[#0E0E1E] font-[400] hidden md:block">OR</div>
        <button
          onClick={open}
          className="text-[12px] text-[#0E0E1E] font-[500] hover:text-white hover:bg-[#48B825] border border-[#DDDDE3] p-[10px_16px] w-[130px] rounded-[6px]">Browse</button>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export default ImageDropZone;
