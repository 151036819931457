import format from "format-number";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const isValidateEmail = (email: any) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const showConfirmBox = (title: any, text: any, callback: Function) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonColor: "#44972a",
        confirmButtonText: "Yes",
        cancelButtonColor: "#FF5C5C",
        cancelButtonText: "No",
    }).then(async (result) => {
        if (result.isConfirmed) {
            if (callback) {
                callback(result);
            }
        } else {
            MySwal.close();
        }
    });
}

export const getFileUrl = (filepath: any) => (filepath?.startsWith("http") ? filepath : process.env.REACT_APP_SERVER_STOR_URL + filepath);

export const dollarFormatter = format({ prefix: "$", integerSeparator: "," });


export const getRisingPercent = (value1: any, value2: any) => {
    let diff = value1 - value2;
    if (value2 > 0) {
        return ((diff / value2) * 100).toFixed(1);
    }
    return 0;
}
export const getRisingValue = (value1: any, value2: any) => {
    if (value1 >= value2) {
        return "+" + (value1 - value2).toFixed(0);
    } else {
        return "-" + (value2 - value1).toFixed(0);
    }
}

export const getTrimedAddress = (address: any) => {
    if (!address) {
        return "";
    }
    return address.substring(0, 6) + "......" + address.substr(-6);
}

const isDevnet = true;

export const getBlockchainHashLink = (address: any) => {
    if (isDevnet) {
        return `https://explorer.solana.com/tx/${address}?cluster=devnet`;
    }
    return `https://solscan.io/tx/${address}`;
}
export const getBlockchainAccountLink = (address: any) => {
    if (isDevnet) {
        return `https://explorer.solana.com/address/${address}?cluster=devnet`;
    }
    return `https://solscan.io/account/${address}`;
}
export const getBlockchainTokenLink = (address: any) => {
    if (isDevnet) {
        return `https://explorer.solana.com/address/${address}?cluster=devnet`;
    }
    return `https://solscan.io/token/${address}`;
}