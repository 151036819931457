import OverviewIcon from '../assets/icons/navitem-overview.svg';
import OverviewActiveIcon from '../assets/icons/navitem-overview-active.svg';
import StatisticsIcon from '../assets/icons/navitem-statistics.svg';
import StatisticsActiveIcon from '../assets/icons/navitem-statistics-active.svg';
import ClientHistoryIcon from '../assets/icons/navitem-client-history.svg';
import ClientHistoryActiveIcon from '../assets/icons/navitem-client-history-active.svg';
import TokenizedCarIcon from '../assets/icons/navitem-tokenized-car.svg';
import TokenizedCarActiveIcon from '../assets/icons/navitem-tokenized-car-active.svg';
import TokenizedPrjIcon from '../assets/icons/navitem-tokenized-prj.svg';
import TokenizedPrjActiveIcon from '../assets/icons/navitem-tokenized-prj-active.svg';
import TransactionIcon from '../assets/icons/navitem-transaction.svg';
import TransactionActiveIcon from '../assets/icons/navitem-transaction-active.svg';
import CMSIcon from '../assets/icons/navitem-cms.svg';
import CMSActiveIcon from '../assets/icons/navitem-cms-active.svg';
import ContractsIcon from '../assets/icons/navitem-contract.svg';
import ContractsActiveIcon from '../assets/icons/navitem-contract-active.svg';
import RolesIcon from '../assets/icons/navitem-roles.svg';
import RolesActiveIcon from '../assets/icons/navitem-roles-active.svg';
import SettingIcon from '../assets/icons/navitem-setting.svg';
import SettingActiveIcon from '../assets/icons/navitem-setting-active.svg';
import NotificationIcon from '../assets/icons/navitem-notification.svg';
import NotificationActiveIcon from '../assets/icons/navitem-notification-active.svg';
import NFTIcon from '../assets/icons/navitem-nft.svg';
import NFTActiveIcon from '../assets/icons/navitem-nft-active.svg';


export const AdminRoles = {
    SuperAdmin: "Super Admin",
    Admin: "Admin",
    User: "User",
};


export const navitemList = [
    {
        text: "Overview",
        img: OverviewIcon,
        active_img: OverviewActiveIcon,
        path: "/",
        active: 1,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin, AdminRoles.User]
    },
    {
        text: "Statistics",
        img: StatisticsIcon,
        active_img: StatisticsActiveIcon,
        path: "/statistics",
        active: 2,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "Client History",
        img: ClientHistoryIcon,
        active_img: ClientHistoryActiveIcon,
        path: "/users",
        active: 3,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "Tokenized Cars",
        img: TokenizedCarIcon,
        active_img: TokenizedCarActiveIcon,
        path: "/cars",
        active: 4,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "Tokenized Projects",
        img: TokenizedPrjIcon,
        active_img: TokenizedPrjActiveIcon,
        path: "/projects",
        active: 5,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "All Transactions",
        img: TransactionIcon,
        active_img: TransactionActiveIcon,
        path: "/transactions",
        active: 6,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "Payments And Withdrawals",
        img: TransactionIcon,
        active_img: TransactionActiveIcon,
        path: "/pws",
        active: 7,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "NFT Transactions",
        img: NFTIcon,
        active_img: NFTActiveIcon,
        path: "/nfts",
        active: 13,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "CMS",
        img: CMSIcon,
        active_img: CMSActiveIcon,
        path: "/cms",
        active: 8,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin],
        childItems: [
            {
                text: "Blog",
                path: "/cms/blog",
                active: 1,
            },
            {
                text: "Page",
                path: "/cms/page",
                active: 2,
            },
            {
                text: "FAQ",
                path: "/cms/faq",
                active: 3,
            }
        ]
    },
    {
        text: "Contracts",
        img: ContractsIcon,
        active_img: ContractsActiveIcon,
        path: "/contracts",
        active: 9,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
    {
        text: "Roles",
        img: RolesIcon,
        active_img: RolesActiveIcon,
        path: "/roles",
        active: 10,
        roles: [AdminRoles.SuperAdmin]
    },
    {
        text: "Settings",
        img: SettingIcon,
        active_img: SettingActiveIcon,
        path: "/settings",
        active: 11,
        roles: [AdminRoles.SuperAdmin]
    },
    {
        text: "Notification",
        img: NotificationIcon,
        active_img: NotificationActiveIcon,
        path: "/notification",
        active: 12,
        roles: [AdminRoles.SuperAdmin, AdminRoles.Admin]
    },
];

export const DATETIME_FORMAT = {
    SHORT_DATE_STR: 'D MMM YYYY',
    SHORT_DATE: 'YYYY-MM-DD',
    TIME_24: 'HH:mm A'
};

export const DocumentTypes = [
    "Contract", "Policies", "Certificates"
];

export const TransactionStatuses = [
    "Pending", "Completed", "Rejected", "Holded"
];